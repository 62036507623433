.App {
  height: 100vh;
  width: 100vw;
  background-color: black;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
}

@keyframes blink {
  0%,
  50% {
    visibility: visible;
  }
  51%,
  100% {
    visibility: hidden;
  }
}

.blink {
  color: white;
  animation: blink 1.5s steps(1) infinite;
}
